import React,{useState} from "react";
import Layout from "../../Components/Layout/Layout";
import bgimage from '../../Images/Pages/banner.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons'
import ActivityCard from "../../Components/Activity/ActivityCard";
import { useSelector } from "react-redux";
function Activities (){
  const ActivityListing=useSelector((state) => state.hotels.ActivityListing);
const[newActivityListing,setNewActivityListing]=useState(ActivityListing.tours);
const handleCheckboxChange = (event) => {
       
      };
      const handleMealTypeChange = (event) => {
      
      };
    
      const handleFacilityChange = (event) => {
     
      };
    return(
        <>
        <Layout>
        {/* <div className='contact-img'>
        <img src={bgimage} />
      </div> */}
      <div className='container'>
        <div className='row mt-5'>
        <div className='col-md-3 col-md-pull-9 '>
            <div className='page-sidebar'>
            {/* <div className='widget widget_price_filter'>
            <h3 className='form-label'>Search Hotel By Name</h3>
           
              </div> */}
              {/* <div className='widget widget_price_filter'>
                <div className='mb-0'>
                  <h3 className='form-label'>Price Level</h3>
                  <div ref={sliderRef} />
                  <div className='pt-2'>
                    <div className='fw-bold mb-2'>
                      Min: <span id='kt_slider_basic_min'>{minValue} </span>
                    </div>
                    <div className='fw-bold mb-2'>
                      Max: <span id='kt_slider_basic_max'>{maxValue} </span>
                    </div>
                  </div>
                  <button onClick={PriceFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
              </div> */}
              <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' >                
                  <h3>Filter by Rating</h3>
                <FontAwesomeIcon icon={faAngleDown}/>
                </div>
               
                  <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' 
                      className='custom-textbox' 
                      onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button  className='btn btn-warning m-2'>Apply</button>
                </div>
                  
              </div>

              {/* <button className='btn select-styling search-btn1'>Filter</button> */}
            </div>
          </div>
          <div className='col-md-9 col-md-push-3'>
            <ActivityCard newActivityListing={newActivityListing}/>
            {/* <HotelCard hotelid={hotelid}/> */}
        </div>
        </div>

      </div>
        </Layout>
        </>
    );
}

export default Activities;